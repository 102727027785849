import * as React from "react";

const items = [
  { date: "april 29th, 2016", content: "Four old men had dinner." },
  {
    date: "august 7th, 2009",
    content: `Dichotic alumni news:

Scott and Raland are playing with <a href="http://www.selectanddismember.com/">Select and Dismember</a>. First show is August 15th, at Coconut Grove. I think Scott is a full-fledged member of <a href="http://cephalectomy.com/">Cephalectomy</a> now too.

Neil and Jeff are playing in <a href="http://tualmasok.netau.net/">Tual Masok</a>. They've even got videos on <a href="http://www.youtube.com/user/TualMasokMetal">YouTube</a>.

I spend all of my free time maintaining this site.`,
  },
  {
    date: "june 18th, 2008",
    content: `Scott plays bass on <a href="http://cephalectomy.com/">Cephalectomy</a>'s latest release, <a href="http://www.cephalectomy.com/tdcm/tdcmsample.mp3">The Dream Cycle Mythos</a>. A 23 minute epic. Raland shows up in there somewhere too.`,
  },
  {
    date: "february 9th, 2007",
    content: `The lyrics to <a href="http://dichotic.com/media/Dichotic-spilling;.mp3">spilling;</a> are taken from <a href="http://dichotic.com/images/spilling.jpg">a poem, supposedly written by the Zodiac killer</a>. The star of a new <a href="http://www.zodiacmovie.com/">movie</a>.

<a href="http://dichotic.com/media/Dichotic-spilling;.mp3">spilling;</a> does not appear on the soundtrack, but it is available <a href="http://dichotic.com/media/Dichotic-spilling;.mp3">here</a>.
`,
  },
  {
    date: "december 23rd, 2006",
    content: `In three days, our "days dead" count will be at 2006 and the year will be 2006. The year and the count will never match again.`,
  },
  {
    date: "october 29th, 2006",
    content: `<img src="/images/wedding2006.jpg" title="The annual Dichotic wedding reunion." alt="The annual Dichotic wedding reunion." width="480" height="277">`,
  },

  {
    date: `january 22nd, 2006`,
    content: `<a href="http://homokaasu.org/gematriculator/" target="_blank"><img src="/images/e55.jpg" alt="This site is certified 55% EVIL by the Gematriculator" width="175" height="80"></a>`,
  },
  {
    date: `november 23rd, 2005`,
    content: `More dead metal music from the dead band: <a href="http://dichotic.com/media/Dichotic-FacingUpForcedDown.mp3">Facing Up, Forced Down</a>.

It was recorded on October 18th, 2003, but we're (I'm) terrible at finishing projects. The writing part isn't too bad, but recording/mixing/mastering is awful.`,
  },
  {
    date: `october 31st, 2005`,
    content: `Happy Halloween.

<img title="The Great Pumpkinhead" alt="The Great Pumpkinhead" src="/images/hallow13.jpg">`,
  },
  {
    date: `september 20th, 2005`,
    content: `<a href="/images/lunenburg.jpg"><img src="/images/kilt.jpg" title="I am wearing a kilt." alt="Some guy in a kilt" align="left"></a>`,
  },
  {
    date: `june 21st, 2005`,
    content: `It's been many months, and we haven't done anything with that song that was supposed to be ready in a few months.`,
  },
  {
    date: `february 7th, 2005`,
    content: `We finally got around to mixing <a href="/discography#misc">Clique War</a>. It was recorded during a one-week-reunion, in May 2004.
    
With any luck, we'll have another one ready in a few months.`,
  },
  {
    date: `june 30th, 2004`,
    content: `Today is the third anniversary of our demise. In order to celebrate, we're releasing <a href="/discography">a bunch of music</a>:
<ul>
<li>All tracks from our first CD, <a href="/discography#cid"><b>Collapse Into Despair</b></a>, are now available for download.</li>
<li>A <b><i>really old</i></b> song called <a href="/discography#misc">Whispers of Immortality</a>, recorded thousands of years ago.</li>
</ul>
We also redesigned the site. Now with <i>even less content</i>!`,
  },
];

const NewsItem = (props) => (
  <tr className="bkg_02">
    <td className="cell-format" valign="top">
      {props.date}
    </td>
    <td
      className="cell-format"
      dangerouslySetInnerHTML={{ __html: props.content }}
    />
  </tr>
);

const News = () => (
  <>
    {items.map((i) => (
      <NewsItem key={i.date} date={i.date} content={i.content} />
    ))}
  </>
);

export default News;
