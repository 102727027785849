import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import News from "../components/news"

const IndexPage = () => (
  <Layout>
    <Seo title="news" />
    <News />
  </Layout>
)

export default IndexPage
